body {
    overflow-x: hidden;
}

.award {
    background-color: gold;
    display: grid;
    width: 100%;
    grid-template-columns: 60% 40%;
    justify-content: center;
    align-items: center;
    padding: 5%;
    font-size: 15px;
}

.blank {
    height: 20px;
}

.bold {
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
}

.btn-sm {
    height: 40px;
    width: 160px;
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    background-color: #A5C9FF;
    text-decoration: none;
}

.btn-sm:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: rgb(224, 224, 224);
    color: #A5C9FF;
    transition: 250ms;
    border: black;
}

.description-box {
    height: auto;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1%;
    margin-bottom: 2%;
    font-size: 16px;
}

.img-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    column-gap: 3px;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 5%
}

.img-grid-item {
    font-size: 16px;
}

.link-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 200px;
    grid-row-gap: 10px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: center;
    justify-content: center;
    text-align: center;
}

.pointer {
    font-size: 30px;
    color: white;
}

.portfolio-button {
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    color: #A5C9FF;
}

.portfolio-button:hover {
    color: white;
    text-decoration: none;
}

.portfolio-button:visited {
    color: #A5C9FF;
    text-decoration: none;
}

.project-img {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1000px;
}

.project-top {
    display: grid;
    text-align: center;
    padding: 5%;
    background-color:#A5C9FF;
    width: 100%;
    grid-template-columns: 10% 80% 10%;
    justify-content: center;
    align-items: center;
}

.experience-top {
    display: grid;
    text-align: center;
    padding: 2%;
    background-color:#1f1f1f;
    width: 100%;
    grid-template-columns: 10% 80% 10%;
    justify-content: center;
    align-items: center;
}

.TL-headers {
    text-align: center;
}

.tool-database {
    float: left;
    background-color: rgb(208, 223, 255);
    border-radius: 4px;
    font-size: 21px;
    padding: 10px 10px;
    margin: 4px 4px;
    color: black;
}

.tool-deploy {
    float: left;
    background-color: rgb(239, 220, 255);
    border-radius: 4px;
    font-size: 21px;
    padding: 10px 10px;
    margin: 4px 4px;
    color: black;
}

.tool-framework {
    float: left;
    background-color: rgb(214, 255, 209);
    border-radius: 4px;
    font-size: 21px;
    padding: 10px 10px;
    margin: 4px 4px;
    color: black;
}

.tool-ide {
    float: left;
    background-color: rgb(226, 226, 226);
    border-radius: 4px;
    font-size: 21px;
    padding: 10px 10px;
    margin: 4px 4px;
    color: black;
}

.tool-language {
    float: left;
    background-color: rgb(255, 245, 191);
    border-radius: 4px;
    font-size: 21px;
    padding: 10px 10px;
    margin: 4px 4px;
    color: black;
}

.tools-links {
    height: auto;
    display: grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    margin: 5%;
    cursor: default;
}

@media screen and (max-width: 1050px) {
    .award {
        grid-template-columns: 100%;
        text-align: center;
        gap: 20px;
        font-size: 19px;
    }
}

@media screen and (max-width: 500px) {

    .btn-sm {
        height: 35px;
        width: 150px;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
    }

    .tool-database {
        font-size: 16px;
    }

    .tool-deploy {
        font-size: 16px;
    }

    .tool-framework {
        font-size: 16px;
    }

    .tool-ide {
        font-size: 16px;
    }

    .tool-language {
        font-size: 16px;
    }    
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Didot, serif';
}

body {
  overflow-x: hidden;
}

.contact-title {
    background-color:#A5C9FF;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    padding: 5%;
    display: flex;
}

.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 50px 50px;
    padding-top: 5%;
    grid-column-gap: 20%;
    justify-content: center;
}

.grid-item {
    padding: 10px;
    font-size: 80px;
    text-align: center;
}

.fa-envelope {
    color: #fff;
    cursor: pointer;
}

.LI-logo {
    text-decoration: none;
    color: #fff;
}


/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    font-size: 16px;
    visibility: hidden;
    background-color: white;
    color: black;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    width: 120px;
    top: 100%;
    left: 50%; 
    margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }


Form {
    border-radius: 5px;
    padding: 120px;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 1.5rem;
}

Input {
    align-content: center;
    justify-content: center;
    font-size: 1.5rem;
    padding:8px 8px 8px;
    border-radius: 6px;

}

textarea#message {
    width:100%;
    font-size: 1.5rem;
	line-height:1.5;
	padding:8px 8px 8px;
    border-radius: 6px;
}

@media screen and (max-width: 960px) {
    
    Form {
        border-radius: 2px;
        padding: 50px;
        font-size: 1rem;
    }
    
    Input {
        align-content: center;
        justify-content: center;
        font-size: 1rem;
        padding:8px 8px 8px;
    }
    
    textarea#message {
        width:100%;
        height: 7rem;
        max-width:100%;
        font-size: 1rem;
        line-height: 1;
        padding:8px 8px 8px;
    }

}

@media screen and (max-width: 540px) {
    
    .contact-title {
        font-size: 2rem;
    }

    .grid-item {
        font-size: 60px;
    }
}

body {
    overflow-x: hidden;
    background: #1f1f1f;
    color: white;
}

.about-btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    color: black;
    cursor: pointer;
    background-color: #A5C9FF;
    text-decoration: none;
    text-align: center;
    width: 180px;
    margin: 0 auto;
}

.about-btn:hover {
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: rgb(224, 224, 224);
    color: #A5C9FF;
    transition: 250ms;
    border: black;
}
 
.bio {
    margin: 5%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.bio-container {
    margin: 5%;
    font-size: 17px; 
}

.bio-container-sm {
    margin: 5%;
    font-size: 17px; 
}

.bio-intro {
    font-size: 35px;
}

.bio-text {
    font-size: 17px;  
}

.button-bar {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 34%;
    grid-row-gap: 10px;
    margin-top: 2%;
    margin-bottom: 2%;
    text-align: center;
}

.center {
    text-align: center;
    margin: 6%;
}

.link {
    color: #A5C9FF;
    text-decoration: none;
}

.link:hover {
    color: white;
}

@media screen and (max-width: 700px) {


    .bio {
        grid-template-columns: 100%;
    }

    .bio-intro {
        font-size: 30px;
    }
}

@media screen and (max-width: 600px) {
    
    .bio-intro {
        font-size: 20px;
    }

    .button-bar {
        grid-template-columns: 100%;
    }

}
.button-container {
    text-align: center;
    height: 100px;
    justify-content: center;
    align-content: center;
    display: grid;
}

.certifications-grid {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 10px 10px 10px;
}

.cert {
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    margin: 0 auto;
}

.experience-grid {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.experience-item {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.experience-item:hover {
    padding: 5px 5px;
    transition: all 0.3s ease-out;
    background: rgb(227, 244, 255);
    color: #000;
    transition: 250ms;
    visibility: visible;
}

.name {
    padding: 10px;
    font-size: 1.5rem;
    background-color:#fff;
    color: black;
    text-decoration: none;
}

.project-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px 10px 10px 10px;
}

.project-item {
    border: 4px solid rgb(227, 244, 255);
    border-radius: 4px;
    float: left;
    height: 300px;
    justify-content: center;
    align-items: center;
    background: #fff;
    overflow-y: hidden;
    margin: 0 auto;
    max-width: 400px;
    text-decoration: none;
}

.project-item:hover {
    padding: 5px 5px 5px 5px;
    transition: all 0.3s ease-out;
    background: rgb(227, 244, 255);
    color: #000;
    transition: 250ms;
    visibility: visible;
}

.project-title {
    color: black;
    background-color:#A5C9FF;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    padding: 2%; 
    display: flex;
}

@media screen and (min-width: 1000px) {
    .project-grid {
        grid-template-columns: 33.3% 33.3% 33.4% ;
    }
}

@media screen and (max-width: 650px) {

    .project-grid {
        grid-template-columns: 100%;
        max-width: 620px;

    }
    .project-item {
        max-width: 620px;
    }

}

@media screen and (max-width: 500px) {
    
    .project-grid {
        max-width: 580px;

    }
    .project-item {
        max-width: 580px;
    }

    .project-title {
        font-size: 2rem;
    }
}

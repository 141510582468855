.fa-bars {
    color: #fff;
}

.menu-icon {
    display: none;
}

.navbar-items {
    background: #1f1f1f;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #A5C9FF;
    border-radius: 4px;
    transition: all 0.3s ease-out;
}

.nav-links-mobile {
    display: none;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.navbar-logo-link {
    text-decoration: none;
    justify-content: start;
    margin-left: 4px;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

@media screen and (max-width: 960px) {

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
    
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .navbar-items {
        position: relative;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%)
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 455px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #1f1f1f;
        left: 0;
        opacity: 0.98;
        transition: all 0.5s ease;
        z-index: 1;
    }
}
